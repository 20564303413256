import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// General
import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import DensityMedium from "@mui/icons-material/DensityMedium";
import { BrowserRouter } from "react-router-dom";
import { LocalizationNS, localStorageIdentifiers, setLocalStorage, useI18n, } from "compass-commons";
import { AllowedModules, TokenIdentifiers, } from "compass-shared-services";
// Components
import { MenuItem } from "@mui/material";
import NestedMenuItem from "./NestedMenuItem";
import AvatarIcon from "../../assets/icons/AvatarIcon";
import "./OptionsMenu.module.css";
import UserManagerService from "../../services/UserManagerService";
import ExtendedLocalStorageIdentifiers from "../../model/ExtendedLocalStorageIdentifiers";
import { getOrganization } from "../../utils/UserUtils";
export default function OptionsMenu(props) {
    var _a, _b;
    const { allowedModules, user, customers } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const selectedOption = window.location.pathname;
    const [orgSelectedOption, setOrgSelectedOption] = React.useState(customers ? `org-${(_a = getOrganization(customers)) === null || _a === void 0 ? void 0 : _a.id}` : null);
    useEffect(() => {
        var _a;
        setOrgSelectedOption(customers ? `org-${(_a = getOrganization(customers)) === null || _a === void 0 ? void 0 : _a.id}` : null);
    }, [customers]);
    const { t: translate } = useI18n();
    const menuItems = {
        [AllowedModules.OPERATION]: {
            code: appConfig.COMPASS_OPERATION_PATH,
            name: translate("menu.operation", { ns: LocalizationNS.HEADER }),
            url: appConfig.COMPASS_OPERATION_PATH,
        },
        [AllowedModules.DATAEXPLORER]: {
            code: appConfig.COMPASS_DATA_EXPLORER_PATH,
            name: translate("menu.dataExplorer", { ns: LocalizationNS.HEADER }),
            url: appConfig.COMPASS_DATA_EXPLORER_PATH,
        },
        [AllowedModules.CONFIGURATION]: {
            code: appConfig.COMPASS_CONFIGURATION_PATH,
            name: translate("menu.configuration", { ns: LocalizationNS.HEADER }),
            url: appConfig.COMPASS_CONFIGURATION_PATH,
        },
        [AllowedModules.INTEGRATIONS]: {
            code: appConfig.COMPASS_INTEGRATION_MANAGER_PATH,
            name: translate("menu.integration-manager", {
                ns: LocalizationNS.HEADER,
            }),
            url: appConfig.COMPASS_INTEGRATION_MANAGER_PATH,
        },
        [AllowedModules.USERMANAGER]: {
            code: appConfig.COMPASS_USER_MANAGER_PATH,
            name: translate("menu.user-manager", {
                ns: LocalizationNS.HEADER,
            }),
            url: appConfig.COMPASS_USER_MANAGER_PATH,
        },
        [AllowedModules.DASHBOARD]: {
            code: "dashboard",
            name: translate("menu.dashboard", {
                ns: LocalizationNS.HEADER,
            }),
            url: appConfig.COMPASS_DASHBOARDS_PATH,
        },
    };
    const loggoutMenu = {
        code: "log_out",
        name: translate("menu.logout", { ns: LocalizationNS.HEADER }),
        isLogout: true,
    };
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const orgChangeEvent = () => {
        setLocalStorage(localStorageIdentifiers.ALL_TABS_SYNC, "orgChanged", {
            namespace: appConfig.NAMESPACE,
        });
        window.location.reload();
    };
    const signOutHandler = () => {
        const signOut = document.getElementById("signOut-div");
        signOut.click();
    };
    const handleItemClick = (_id) => {
        if (_id.startsWith("org-")) {
            const newCustomerId = _id.replace("org-", "");
            const customer = customers.customerDTOList.find((c) => c.id === newCustomerId);
            if (customer) {
                UserManagerService.setCustomer(newCustomerId, user.userId)
                    .then(() => {
                    localStorage.removeItem(`${appConfig.NAMESPACE}_${TokenIdentifiers.TOKEN}`);
                    setLocalStorage(ExtendedLocalStorageIdentifiers.SESSION_ORG_ID, customer.externalOrganizationId, {
                        namespace: appConfig.NAMESPACE,
                    });
                    orgChangeEvent();
                })
                    // eslint-disable-next-line no-console
                    .catch((error) => console.log(error));
            }
        }
        setAnchorEl(null);
    };
    const handleLogoutClick = (_id) => {
        setAnchorEl(null);
        signOutHandler();
    };
    const doNothing = () => {
        setAnchorEl(null);
    };
    return (_jsxs("div", Object.assign({ className: "options-menu-div" }, { children: [_jsx(IconButton, Object.assign({ "aria-label": "more", id: "long-button", "aria-controls": "long-menu", "aria-expanded": open ? "true" : undefined, "aria-haspopup": "true", "data-cr": "menu-button", onClick: handleClick, sx: {
                    height: "30px",
                    width: "30px",
                } }, { children: _jsx(DensityMedium, { htmlColor: "#007aaa" }, void 0) }), void 0), _jsx(BrowserRouter, { children: _jsxs(Menu, Object.assign({ id: "long-menu", MenuListProps: {
                        "aria-labelledby": "long-button",
                    }, anchorEl: anchorEl, open: open, onClose: handleClose }, { children: [_jsx(MenuItem, Object.assign({ style: { pointerEvents: "none" }, onClick: doNothing }, { children: _jsx("div", { children: _jsxs("div", Object.assign({ style: { display: "flex" } }, { children: [_jsx("div", Object.assign({ style: { display: "flex" } }, { children: _jsx(AvatarIcon, { width: 48, height: 48 }, void 0) }), void 0), _jsxs("div", Object.assign({ className: "menu-user-header" }, { children: [_jsx("div", Object.assign({ className: "menu-name-header", "data-cr": "menu-name-header" }, { children: user && (_jsx("span", { children: `${user === null || user === void 0 ? void 0 : user.firstName} ${user === null || user === void 0 ? void 0 : user.lastName}` }, void 0)) }), void 0), _jsx("div", Object.assign({ className: "org-header", "data-cr": "menu-org-header" }, { children: _jsx("span", { children: (_b = getOrganization(customers)) === null || _b === void 0 ? void 0 : _b.name }, void 0) }), void 0), _jsx("div", Object.assign({ className: "org-header", "data-cr": "menu-email-header" }, { children: _jsx("span", { children: user === null || user === void 0 ? void 0 : user.email }, void 0) }), void 0)] }), void 0)] }), void 0) }, void 0) }), void 0), allowedModules === null || allowedModules === void 0 ? void 0 : allowedModules.reduce((acc, item) => {
                            const { code, name, children, url } = menuItems[item.module] || {};
                            return ((code &&
                                acc.concat(_jsx(NestedMenuItem, { id: code, name: name, childrenItems: children, onClick: handleItemClick, url: url, selectedOption: selectedOption }, `${name}-${code}`))) ||
                                acc);
                        }, []), _jsx(NestedMenuItem, { id: "switch-orgs-id", name: translate("menu.switchOrg", { ns: LocalizationNS.HEADER }), onClick: handleItemClick, childrenItems: !(customers === null || customers === void 0 ? void 0 : customers.customerDTOList)
                                ? [
                                    {
                                        code: "empty-org",
                                        name: translate("menu.noOrg", {
                                            ns: LocalizationNS.HEADER,
                                        }),
                                    },
                                ]
                                : customers === null || customers === void 0 ? void 0 : customers.customerDTOList.map((c) => {
                                    return { code: `org-${c.id}`, name: c.name };
                                }), selectedOption: orgSelectedOption }, "switch-orgs"), _jsx(NestedMenuItem, { id: loggoutMenu.code, name: loggoutMenu.name, onClick: handleLogoutClick, selectedOption: selectedOption }, loggoutMenu.code)] }), void 0) }, void 0)] }), void 0));
}
