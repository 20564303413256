import { getLocalStorage } from "compass-commons";
import ExtendedLocalStorageIdentifiers from "../model/ExtendedLocalStorageIdentifiers";
const getOrganization = (customerList) => {
    const sessionOrgId = getLocalStorage(ExtendedLocalStorageIdentifiers.SESSION_ORG_ID, appConfig.NAMESPACE);
    const sessionCurrentCustomer = customerList === null || customerList === void 0 ? void 0 : customerList.customerDTOList.find((c) => sessionOrgId
        ? sessionOrgId === c.externalOrganizationId
        : c.id === customerList.currentCustomerId);
    return sessionCurrentCustomer;
};
// eslint-disable-next-line import/prefer-default-export
export { getOrganization };
