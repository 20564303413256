import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// General
import React from "react";
import { getLocalStorage, getUrl, localStorageIdentifiers, sessionStorageIdentifiers, } from "compass-commons";
// Style
import { AppBar, Toolbar } from "@mui/material";
import "./headerNavBar.module.css";
// Components
import OptionsMenu from "./OptionsMenu";
// Assets
import logo from "../../assets/images/logo-header.png";
import IconHelp from "../../assets/icons/IconHelp";
import AvatarIcon from "../../assets/icons/AvatarIcon";
import { getOrganization } from "../../utils/UserUtils";
const { DMS_HELP_LINK_URL } = appConfig;
const HeaderNavBar = ({ allowedModules, customers, user, }) => {
    function openDynamicHelp() {
        const locale = getLocalStorage(localStorageIdentifiers.DMS_DEFAULT_LOCALE, appConfig.NAMESPACE);
        const uri = {
            locale,
            component: sessionStorage.getItem(sessionStorageIdentifiers.MAIN_CONTEXT_HELP_KEYWORD),
        };
        const urlPath = getUrl(DMS_HELP_LINK_URL, uri);
        const newWindow = window.open(urlPath, "_blank");
        if (newWindow) {
            newWindow.opener = null;
        }
    }
    return (_jsx(AppBar, Object.assign({ position: "static", color: "inherit" }, { children: _jsxs(Toolbar, Object.assign({ style: {
                minHeight: "50px",
            } }, { children: [_jsx("div", Object.assign({ style: {
                        flexGrow: 1,
                    } }, { children: _jsx("img", { src: logo, alt: "AVIGILON DMS logo", height: "20px" }, void 0) }), void 0), _jsxs("div", Object.assign({ style: { display: "flex" } }, { children: [_jsxs("div", Object.assign({ className: "user-header" }, { children: [user && (_jsx("div", Object.assign({ className: "name-header", "data-cr": "name-header" }, { children: _jsx("span", { children: `${user === null || user === void 0 ? void 0 : user.firstName} ${user === null || user === void 0 ? void 0 : user.lastName}` }, void 0) }), void 0)), _jsx("div", Object.assign({ className: "org-header", "data-cr": "org-header" }, { children: _jsx("span", { children: customers && getOrganization(customers).name }, void 0) }), void 0)] }), void 0), _jsxs("div", Object.assign({ style: { display: "flex" }, className: "buttons-header" }, { children: [_jsx("div", { children: _jsx(AvatarIcon, { width: 24, height: 24 }, void 0) }, void 0), _jsx("div", { className: "vertical-line-header" }, void 0), _jsx("div", Object.assign({ role: "presentation", className: "helpLogo", style: {
                                        cursor: "pointer",
                                    }, onClick: () => openDynamicHelp(), onKeyDown: () => { } }, { children: _jsx(IconHelp, {}, void 0) }), void 0), _jsx(OptionsMenu, { allowedModules: allowedModules, user: user, customers: customers }, void 0)] }), void 0)] }), void 0)] }), void 0) }), void 0));
};
export default HeaderNavBar;
